import {
  BulkDeleteWithUndoButton,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditButton,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

const transformData = (data) => ({
  ...data,
  startDate: data.startDate ? new Date(data.startDate).toISOString() : null,
  endDate: data.endDate ? new Date(data.endDate).toISOString() : null,
});

export const ApiSubscriptionsList = (props) => {
  return (
    <List {...props} perPage={50} filter={{ isDefault: false, isExpired: false }}>
      <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} />}>
        <TextField source="userId" />
        <TextField source="tariffId" label="TariffId" />
        <ReferenceField reference="api-tariffs" source="tariffId" link={false} label="TariffName">
          <TextField source="name" label="TariffName" />
        </ReferenceField>
        <DateField source="startDate" />
        <DateField source="endDate" />
        <TextField source="comment" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const ApiSubscriptionsEdit = () => {
  return (
    <Edit transform={transformData}>
      <SimpleForm>
        <NumberInput source="userId" required />
        <ReferenceInput reference="api-tariffs" source="tariffId" link={false} label="Tariff" required>
          <SelectInput optionText="name" label="Tariff" />
        </ReferenceInput>
        <DateInput source="startDate" required />
        <DateInput source="endDate" required />
        <TextInput source="comment" />
      </SimpleForm>
    </Edit>
  );
};

export const ApiSubscriptionsCreate = () => {
  return (
    <Create transform={transformData}>
      <SimpleForm>
        <NumberInput source="userId" required />
        <ReferenceInput reference="api-tariffs" source="tariffId" link={false} label="Tariff" required>
          <SelectInput optionText="name" label="Tariff" />
        </ReferenceInput>
        <DateInput source="startDate" required />
        <DateInput source="endDate" required />
        <TextInput source="comment" />
      </SimpleForm>
    </Create>
  );
};
