import { Add, Delete, ArrowDownward } from '@mui/icons-material';
import { Button, TextField, Switch } from '@mui/material';
import { ColumnContainer, RowContainer } from '../styled';
import { CoinAllocationBatch, CoinAllocationUnlockType, formatDate, initialTokensModal } from './utils';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Pagination,
} from '@mui/material';
import { useState } from 'react';
import { useNotify } from 'ra-core';
import { TextFieldNumber } from '../../../inputs/textfield-number';

type Props = {
  batchesArray: CoinAllocationBatch[];
  changeBatch: (index: number, values: Partial<Record<keyof CoinAllocationBatch, any>>) => void;
  deleteBatch: (index: number) => void;
  addBatch: () => void;
  unlockType: CoinAllocationUnlockType;
  tokens: number | null;
  tgeDate: string;
};

export const BatchList = ({ batchesArray, changeBatch, deleteBatch, addBatch, unlockType, tokens, tgeDate }: Props) => {
  const firstPage = 1;
  const pageSize = 100;

  const notify = useNotify();
  const [expanded, setExpanded] = useState(false);
  const [page, setPage] = useState(firstPage);
  const [tokensModal, setTokensModal] = useState(initialTokensModal);

  const handleChangeExpanded = (_, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const handleChangePage = (_, page: number) => {
    setPage(page);
  };

  const sortedBatches = batchesArray.sort((a, b) => {
    if (a.is_tge && !b.is_tge) {
      return -1;
    }
    if (!a.is_tge && b.is_tge) {
      return 1;
    }
    return 0;
  });

  const pageBatches = sortedBatches.slice((page - 1) * pageSize, page * pageSize);
  const pagesCount = Math.ceil(sortedBatches.length / pageSize);

  const handleToggleTokensModal = (isOpen: boolean, batchIndex?: number) => {
    if (!tokens) {
      notify('Поле "Tokens Amount" не заполнено');
      return;
    }

    if (batchIndex === undefined) {
      setTokensModal(initialTokensModal);
      return;
    }

    const { unlock_percent } = batchesArray[batchIndex];

    const initialValue = unlock_percent > 0 ? Math.round((tokens / 100) * unlock_percent) : 0;

    setTokensModal({
      ...initialTokensModal,
      batchIndex,
      value: String(initialValue),
      isOpen,
    });
  };

  const handleApplyTokens = () => {
    if (tokensModal.batchIndex === undefined || !tokensModal.isValid || !tokens) {
      return;
    }

    const tokensPercent = 100 / (tokens / Number(tokensModal.value));

    changeBatch(tokensModal.batchIndex, { unlock_percent: Math.round(tokensPercent * 1000) / 1000 });
    handleToggleTokensModal(false);
  };

  const handleChangeTokens = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;

    value = value.replace(/[,.]/g, '');

    const parsedValue = parseFloat(value);

    setTokensModal((prevTokensData) => ({
      ...prevTokensData,
      value,
      isValid: !isNaN(parsedValue) && parsedValue >= 0,
    }));
  };

  const handleAddBatch = () => {
    setPage(Math.ceil((sortedBatches.length + 1) / pageSize));
    addBatch();
  };

  const handleDeleteBatch = (index: number) => {
    deleteBatch(index);

    if (pagesCount > 1 && pageBatches.length === 1) {
      setPage(page - 1);
    }
  };

  const handleChangeTge = (batchIndex: number, checked: boolean) => {
    if (checked === true) {
      changeBatch(batchIndex, { is_tge: checked, date: new Date(tgeDate).toISOString() });
      return;
    }

    changeBatch(batchIndex, { is_tge: checked });
  };

  return (
    <Accordion style={{ marginBottom: '20px' }} expanded={expanded} onChange={handleChangeExpanded}>
      <AccordionSummary expandIcon={<ArrowDownward style={{ fill: '#3F51B5' }} />}>Скрыть/показать</AccordionSummary>
      <AccordionDetails>
        {expanded && (
          <>
            {pageBatches.map((batch, index) => {
              const disabledSwitch =
                (unlockType === CoinAllocationUnlockType.LINEAR && !batch.is_tge) ||
                (unlockType === CoinAllocationUnlockType.NONLINEAR && index > 0);

              let batchIndex = index + pageSize * (page - 1);

              return (
                <div key={batchIndex}>
                  <Typography>Batch {batchIndex}</Typography>
                  <RowContainer withBorder={batch.is_tge}>
                    <ColumnContainer>
                      <TextField
                        hiddenLabel={true}
                        id="date"
                        name="date"
                        type="date"
                        value={formatDate(batch.date, true)}
                        onChange={({ target: { value } }) =>
                          changeBatch(batchIndex, { date: new Date(value).toISOString() })
                        }
                      />
                    </ColumnContainer>
                    <div>
                      <label>on TGE</label>
                      <Switch
                        disabled={disabledSwitch}
                        checked={!!batch.is_tge}
                        onChange={(e) => handleChangeTge(batchIndex, e.target.checked)}
                        name="is_tge"
                      />
                    </div>
                    <TextFieldNumber
                      name="unlock_percentage"
                      label="Unlock (%)"
                      value={batch.unlock_percent}
                      onChange={(e) => changeBatch(batchIndex, { unlock_percent: Number(e.target.value) })}
                      margin="normal"
                      required
                      style={{ minWidth: '20%' }}
                      InputProps={{ inputProps: { type: 'number', min: 0, max: 100 } }}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth={true}
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={() => handleToggleTokensModal(true, batchIndex)}
                    >
                      Set Unlock Tokens
                    </Button>

                    <div>
                      <Button
                        type="button"
                        color="inherit"
                        startIcon={<Delete />}
                        onClick={() => handleDeleteBatch(batchIndex)}
                      >
                        Delete
                      </Button>
                    </div>
                  </RowContainer>
                </div>
              );
            })}
            {pagesCount > 1 && (
              <Pagination
                count={pagesCount}
                page={page}
                onChange={handleChangePage}
                color="primary"
                sx={{ marginTop: 3 }}
              />
            )}
            <Button onClick={handleAddBatch} startIcon={<Add />} style={{ margin: '25px 0' }}>
              Add Batch
            </Button>
          </>
        )}
      </AccordionDetails>

      <Dialog open={tokensModal.isOpen}>
        <DialogTitle>Unlock (tokens)</DialogTitle>
        <DialogContent>
          <TextField
            label="Value"
            value={tokensModal.value}
            onChange={handleChangeTokens}
            fullWidth={true}
            InputLabelProps={{ shrink: true }}
            autoFocus={true}
            onFocus={(e) => e.target.select()}
            InputProps={{ inputProps: { type: 'number', min: 0, max: tokens } }}
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleToggleTokensModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleApplyTokens} color="primary" disabled={!tokensModal.isValid}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Accordion>
  );
};
