import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  DeleteButton,
  BulkDeleteWithUndoButton,
  TextInput,
  SimpleForm,
  Edit,
  useNotify,
  Create,
} from 'react-admin';
import { getIsDisabledByRole } from '../../utils/get-disabled-by-role';


export const PartnershipContactsList = (props) => {
  return (
    <List {...props}>
      <Datagrid bulkActionButtons={<BulkDeleteWithUndoButton {...props} disabled={getIsDisabledByRole()} />}>
        <TextField source="telegram" label="Telegram" />
        <EmailField source="email" label="E-mail" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const PartnershipContactCreate = (props) => {
  const notify = useNotify();

  const onSuccess = () => {
    notify('Contact Created');
  };

  return (
    <Create {...props} mutationMode="pessimistic" onSuccess={onSuccess} redirect={(basePath) => basePath}>
      <SimpleForm>
        <TextInput source="telegram" label="Telegram" placeholder="Without @" />
        <TextInput source="email" label="E-mail" type="email" />
      </SimpleForm>
    </Create>
  );
};

export const PartnershipContactEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="telegram" label="Telegram" />
      <TextInput source="email" label="E-mail" type="email" />
    </SimpleForm>
  </Edit>
);
